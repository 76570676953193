import { useContext, useState, useEffect } from "react";
import Button from "../../common/Button";
import { bookingExtras } from "../../../context/bookingExtras";
import { customStylesExtra } from "../../../utils/SelectOptions";
import Select from "react-select";
import "swiper/css";
import "swiper/css/navigation";
import ImageModal from "../Booking/ImageModal";
import { createPortal } from 'react-dom';

const ExtraCard = ({ currency, item, experienceImages, allMembers }) => {
    const { bookingExtraIds, addExtra, removeExtra } = useContext(bookingExtras)
    const [quantity, setQuantity] = useState(0)
    const [options, setOptions] = useState([])
    const [defaultOptions, setDefaultOptions] = useState([])
    const [extraOptions, setExtraOptions] = useState([])
    const [ids, setIds] = useState([])
    const [max_bookable_quantity, setMax_bookable_quantity] = useState()



    useEffect(() => {
        const allIds = []
        bookingExtraIds?.map(extra => {
            allIds.push(extra.extra_id)
            if (extra.extra_id == item.id) {
                setQuantity(extra.quantity)
                setDefaultOptions(extra.defaultOptions)
                setOptions(extra.another_options)
            }
        }
        )
        setIds([...allIds])
    }, [bookingExtraIds, item])

    const handleExtras = () => {
        const type = item?.type;
        let price = type == "person" ? (+quantity * item.pricing) : item.pricing;
    
        if (item?.another_options != null) {
            if (max_bookable_quantity > 0) {
                if (quantity <= max_bookable_quantity && quantity > 0 && options.length === quantity) {
                    if (ids.includes(item?.id)) {
                        removeExtra(item?.id);
                        resetStates();
                    } else {
                        addExtra(item?.id, quantity, item?.type, price, item?.title, options, defaultOptions);
                    }
                }
            } else {
                if (quantity > 0 && options.length === quantity) {
                    if (ids.includes(item?.id)) {
                        removeExtra(item?.id);
                        resetStates();
                    } else {
                        addExtra(item?.id, quantity, item?.type, price, item?.title, options, defaultOptions);
                    }
                }
            }
        } else {
            if (max_bookable_quantity > 0) {
                if (quantity <= max_bookable_quantity && quantity > 0) {
                    if (ids.includes(item?.id)) {
                        removeExtra(item?.id);
                        resetStates();
                    } else {
                        addExtra(item?.id, quantity, item?.type, price, item?.title, options, defaultOptions);
                    }
                }
            } else {
                if (quantity > 0) {
                    if (ids.includes(item?.id)) {
                        removeExtra(item?.id);
                        resetStates();
                    } else {
                        addExtra(item?.id, quantity, item?.type, price, item?.title, options, defaultOptions);
                    }
                }
            }
        }
    };
    

    const resetStates = () => {
        setQuantity(0);
        setOptions([]);
        setDefaultOptions([]);
    };

    const handleRemove = () => {
        setQuantity(+quantity - 1)
        if (options[+quantity - 1]) {
            options.splice(+quantity - 1, 1)
            defaultOptions.splice(+quantity - 1, 1)
        }
    }

    useEffect(() => {
        const selectOptions = []
        item?.another_options?.map(option => { selectOptions.push({ label: option, value: option }) })
        setExtraOptions(selectOptions)
    }, [item])

    useEffect(() => {
        item?.quantity_type == 'booking' ?
            setMax_bookable_quantity(allMembers?.reduce((total, category) => total + category.categoryNo, 0)) :
            setMax_bookable_quantity(item?.max_bookable_quantity)
    }, [item, allMembers])

    const handleInput = (e) => {
        if (!ids.includes(item?.id)) {
            setQuantity(e.target.value)
            if (options?.length > e.target.value) {
                options.splice(e.target.value)
                defaultOptions.splice(e.target.value)
            }
        }
    }

    const [activeSlide, setActiveSlide] = useState(0)
    const [prevSlide, setPrevSlide] = useState(-1)
    const [firstLoad, setFirstLoad] = useState(true)

    useEffect(() => {
        setTimeout(() => { setFirstLoad(false) }, 4000)
    }, [])


    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (item?.images.length > 1) {
                setActiveSlide(activeSlide + 1)
                setPrevSlide(prevSlide > activeSlide ? 0 : prevSlide + 1)
                if (item?.images.length > 2) {
                    if (activeSlide === 2) {
                        setActiveSlide(0)
                        setPrevSlide(activeSlide)
                    }
                } else {
                    if (activeSlide === 1) {
                        setActiveSlide(0)
                        setPrevSlide(activeSlide - 2)
                    }
                }
            }
        }, 4000)
        return () => clearTimeout(timeOut)
    }, [activeSlide, prevSlide, item])

    const [showModal, setShowModal] = useState(false)

    return (
      <div className="extra-card">
            <div className="img-container">
                <div className="banner-swiper extra-swiper">
                    {item?.images.map((img, index) => (
                          index < 3 &&
                        <div className={`
                    ${activeSlide === index ? "active" : ""} 
                    ${prevSlide === index ? "prev" : ""}
                     banner-img extra-swiper-img`} key={index}>

                            <img
                                src={img || experienceImages[0]}
                                alt="img" />
                        </div>
                    ))}
                    <div className="swiper-pagination-bullets extra-swiper-pagination">
                        {item?.images.map((img, index) => (
                            index < 3 && (
                                <div 
                                className={`swiper-pagination-bullet ${activeSlide === index ? "active" : ""}`}
                                key={index}
                                onClick={() => {
                                    setActiveSlide(index);
                                    setPrevSlide(activeSlide);
                                }}
                            >
                                <div className={`points ${firstLoad && 'firstLoad'}`}></div>
                            </div>
                        )
                           
                        ))}
                    </div>
                   
                    {showModal && createPortal(
                        <ImageModal
                            setShowModal={setShowModal}
                            images={item?.images || experienceImages[0]} />,
                        document.body
                    )}
                </div>
            </div>

        <div className="data">
                <div>
                    <h4 className="bold">{item?.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                </div>
                <div className={`type ${ids.includes(item?.id) && 'disabled'}`}>
                    <p className='medium name'>
                        {'Quantity'}
                    </p>
                    <div className='counter'>
                        <div className={`sign ${ids.includes(item?.id) && 'disabled'}`} onClick={() => !ids.includes(item?.id) && handleRemove()}><span>-</span></div>
                        <div>
                            <input
                                className={` ${ids.includes(item?.id) && 'disabled'}`}
                                value={parseInt(quantity, 10)}
                                type='number'
                                onChange={() => !ids.includes(item?.id) && handleInput()} />
                        </div>
                        <div className={`sign ${ids.includes(item?.id) && 'disabled'}`} onClick={() => { !ids.includes(item?.id) && setQuantity(+quantity + 1); } }><span>+</span></div>
                    </div>
                    {max_bookable_quantity > 0 &&
                        <span className="note medium">Max no {max_bookable_quantity}</span>}
                </div>
                {item.another_options && quantity > 0 &&
                    <div className={`type options ${ids.includes(item?.id) && 'disabled'}`}>
                        <p className='medium name'>
                            {/* Uncomment when need to show dynamic data
                            {`Select ${item.option_type} of ${item.option_title} for members`} */}
                        </p>
                        <div className={`selection-group ${ids.includes(item?.id) && 'disabled'}`}>
                            {Array.from({ length: quantity }).map((data, index) => (
                                <Select
                                    defaultValue={defaultOptions[index]}
                                    isSearchable={false}
                                    options={extraOptions}
                                    placeholder={`Select ${item.option_type}`}
                                    className={`test select-drop-down ${ids.includes(item?.id) ? 'disabled ' : ''}`}
                                    noOptionsMessage={() => `no options`}
                                    classNamePrefix="react-select"
                                    styles={customStylesExtra}
                                   
                                    onChange={(e) => {
                                        const updatedOptions = [...options];
                                        updatedOptions[index] = e.value;
                                        setOptions(updatedOptions);
                                        const updatedDefaultOptions = [...defaultOptions];
                                        updatedDefaultOptions[index] = e;
                                        setDefaultOptions(updatedDefaultOptions);
                                        // setOptions(current => [...current, e.value]); 
                                        // setDefaultOptions(current => [...current, e]) 
                                    }}
                                    key={index}
                                    isDisabled={(index > 0 && !options[index - 1]) || ids.includes(item?.id)}
                                />
                            ))}
                        </div>
                    </div>}
                <div className="price-group">
                    <Button 
                    text={ids.includes(item?.id) ? "Remove Extra" : 'Add Extra'}
                        customClass={`
                        ${(quantity <= 0 || (max_bookable_quantity > 0 && quantity > max_bookable_quantity)) && "disabled"}
                        ${(item?.another_options != null && quantity != 0 && options.length != quantity) && 'disabled'}
                        `}
                        type={'button'}
                        onClick={() => {
                            !(item?.another_options != null && quantity != 0 && options.length != quantity)
                                && !(quantity <= 0 || (max_bookable_quantity > 0 && quantity > max_bookable_quantity)) && handleExtras()
                               
                            }}
                    />
                    <div className="price">
                        <span className="medium">{item?.type == 'booking' ? "Per Booking" : item.pricing == 0 ? " Per Person" : "Per Person"}</span>  {/*  removed free */}
                        <p className="bold">{item.pricing == 0 ? "For free" : item.pricing + " " + currency}</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ExtraCard;