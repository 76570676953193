
import { ErrorMessage, Field } from "formik";
import { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import { customStyles } from "../../../utils/SelectOptions";
import Select from "react-select";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const InfoFormGroup = ({
    formik,
    country,
    value,
    index,
    errors,
    touched,
    condition,
    nationalities,
    languages,
    phoneError,
    setPhoneError
}) => {
    const [phoneValue, setPhoneValue] = useState(null)

    useEffect(() => {
        if (phoneValue) {
            parsePhoneNumberFromString(phoneValue)?.isValid() ? setPhoneError(false) : setPhoneError(true)
        }
    }, [phoneValue])

    return (
        <div className="info-form-group">

            <div className="form-group radio">
                {
                    condition?.includes('gender') &&
                    <div className="input-wrapper">
                        <RadioGroup
                            className="radio-group"
                            aria-labelledby="demo-radio-buttons-group-label"
                            name={`${value}.${index}.gender`}
                            onChange={(e) => { formik.setFieldValue(`${value}.${index}.gender`, e.target.value) }}
                        >
                            <FormControlLabel value="male"
                                control={<Radio
                                    sx={{
                                        '&.Mui-checked': {
                                            color: '#000',
                                        },
                                    }}
                                />}
                                label="Male" />
                            <FormControlLabel value="female"
                                control={<Radio
                                    sx={{
                                        '&.Mui-checked': {
                                            color: '#000',
                                        },
                                    }}
                                />}
                                label="Female" />
                        </RadioGroup>
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.gender`} />
                        </p>
                    </div>
                }
            </div>


            <div className="form-group">
                {
                    condition?.includes('title') &&
                    <>
                        <div className="input-wrapper">
                            <label htmlFor="card_number">
                                title
                            </label>
                            <Field
                                placeholder={'Title*'}
                                name={`${value}.${index}.title`}
                                className={"input"}
                                type="text"
                            />
                            <p className="error medium">
                                <ErrorMessage name={`${value}.${index}.title`} />
                            </p>
                        </div>
                        <div className="input-wrapper">
                        </div>
                    </>
                }
            </div>


            <div className="form-group">
                {
                    condition?.includes('first_name') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            first name
                        </label>
                        <Field
                            placeholder={'First Name*'}
                            name={`${value}.${index}.first_name`}
                            className={"input"}
                            type="text"
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.first_name`} />
                        </p>
                    </div>
                }
                {
                    condition?.includes('last_name') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            last name
                        </label>
                        <Field
                            placeholder={'Last Name*'}
                            name={`${value}.${index}.last_name`}
                            className={"input"}
                            type="text"
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.last_name`} />
                        </p>
                    </div>
                }
            </div>

            <div className="form-group">
                {
                    condition?.includes('email') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            email
                        </label>
                        <Field
                            placeholder={'Email Address*'}
                            name={`${value}.${index}.email`}
                            className={"input"}
                            type="email"
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.email`} />
                        </p>
                    </div>
                }
                {
                    condition?.includes('organization') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            organization
                        </label>
                        <Field
                            placeholder={'Organization*'}
                            name={`${value}.${index}.organization`}
                            className={"input"}
                            type="text"
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.organization`} />
                        </p>
                    </div>
                }
            </div>
            <div className="form-group">
                {
                    condition?.includes('phone') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            phone
                        </label>
                        <PhoneInput
                            placeholder="Mobile Number*"
                            international
                            defaultCountry={country}
                            countryCallingCodeEditable={false}
                            onChange={(e) => {
                                if (e) {
                                    setPhoneValue(e)
                                    formik.setFieldValue(`${value}.${index}.phone`, e)
                                }
                            }}
                            onBlur={formik.handleBlur}
                            error={`${touched}.${index}` && `${errors}.${index}`}
                            id={`${value}.${index}.phone`}
                        />
                        <p className="error medium">
                            {phoneError ? <span>{'Invalid number'}</span> :
                                <ErrorMessage name={`${value}.${index}.phone`} />}
                        </p>
                    </div>
                }
                {
                    condition?.includes('address') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            address
                        </label>
                        <Field
                            placeholder={'Address*'}
                            name={`${value}.${index}.address`}
                            className={"input"}
                            type="text"
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.address`} />
                        </p>
                    </div>
                }
            </div>
            <div className="form-group">
                {
                    condition?.includes('personal_id_number') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            personal_id_number
                        </label>
                        <Field
                            placeholder={'ID Number*'}
                            name={`${value}.${index}.personal_id_number`}
                            className={"input"}
                            type="number"
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.personal_id_number`} />
                        </p>
                    </div>
                }
                {
                    condition?.includes('passport_id') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            passport_id
                        </label>
                        <Field
                            placeholder={'Passport ID*'}
                            name={`${value}.${index}.passport_id`}
                            className={"input"}
                            type="text"
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.passport_id`} />
                        </p>
                    </div>
                }
            </div>
            <div className="form-group">
                {
                    condition?.includes('nationality') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            nationality
                        </label>
                        <Select
                            isSearchable={true}
                            options={nationalities}
                            name={`${value}.${index}.nationality`}
                            placeholder={`Select Nationality*`}
                            className="select-drop-down"
                            noOptionsMessage={() => `no options`}
                            classNamePrefix="react-select"
                            styles={customStyles}
                            onChange={(e) => {
                                formik.setFieldValue(`${value}.${index}.nationality`, e.value);
                            }}
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.nationality`} />
                        </p>
                    </div>
                }
                {
                    condition?.includes('language') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            language
                        </label>
                        <Select
                            isSearchable={true}
                            options={languages}
                            name={`${value}.${index}.language`}
                            placeholder={`Select Language*`}
                            className="select-drop-down"
                            noOptionsMessage={() => `no options`}
                            classNamePrefix="react-select"
                            styles={customStyles}
                            onChange={(e) => {
                                formik.setFieldValue(`${value}.${index}.language`, e.value);
                            }}
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.language`} />
                        </p>
                    </div>
                }
            </div>
            <div className="form-group">
                {
                    condition?.includes('date_of_birth') &&
                    <div className="input-wrapper">
                        <label htmlFor="card_number">
                            date_of_birth
                        </label>
                        <DatePicker
                            onChange={(values) => {
                                formik.setFieldValue(`${value}.${index}.date_of_birth`,
                                    values.year + '-' + values?.month?.number.toLocaleString('en-US', { minimumIntegerDigits: 2 }) +
                                    "-" + values.day.toLocaleString('en-US', { minimumIntegerDigits: 2 }))
                            }}
                            placeholder="Select date of birth*"
                        />
                        <p className="error medium">
                            <ErrorMessage name={`${value}.${index}.date_of_birth`} />
                        </p>
                        <div className="icon">
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 0C3.75 0 4 0.25 4 0.5V2H10V0.5C10 0.25 10.2188 0 10.5 0C10.75 0 11 0.25 11 0.5V2H12C13.0938 2 14 2.90625 14 4V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V4C0 2.90625 0.875 2 2 2H3V0.5C3 0.25 3.21875 0 3.5 0ZM13 6H9.75V8.25H13V6ZM13 9.25H9.75V11.75H13V9.25ZM13 12.75H9.75V15H12C12.5312 15 13 14.5625 13 14V12.75ZM8.75 11.75V9.25H5.25V11.75H8.75ZM5.25 15H8.75V12.75H5.25V15ZM4.25 11.75V9.25H1V11.75H4.25ZM1 12.75V14C1 14.5625 1.4375 15 2 15H4.25V12.75H1ZM1 8.25H4.25V6H1V8.25ZM5.25 8.25H8.75V6H5.25V8.25ZM12 3H2C1.4375 3 1 3.46875 1 4V5H13V4C13 3.46875 12.5312 3 12 3Z" fill="#211D33" />
                            </svg>

                        </div>
                    </div>
                }
                <div className="input-wrapper">
                </div>
            </div>
        </div>
    );
}

export default InfoFormGroup;