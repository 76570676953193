import { useContext, useEffect, useState } from "react";
import { isMobileContext } from "../../context/isMobileContext";
import CloseButton from "../common/CloseButton";
import ExtrasDetails from "../common/ExtrasDetails";

const PaymentSummary = ({ bookingData }) => {
  const { isMobile } = useContext(isMobileContext);
  const [openItems, setOpenItems] = useState(false);
  const [date, setDate] = useState(false);

  function formatPrice(value) {
    return Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  const handleSummary = () => {
    if (openItems) {
      setOpenItems(false);
      document.body.classList.remove("no-scroll");
    } else {
      setOpenItems(true);
      document.body.classList.add("no-scroll");
    }
  };

  useEffect(() => {
    const date = new Date(bookingData?.booking_day);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    setDate(
      `${day.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })} ${month} ${year}`
    );
  }, [bookingData]);

  const platformFee = bookingData?.commission_fee/100 ?? 0;

  const calculatePlatformFee = (rate) => {
    return bookingData?.is_commission_included === 0 ? platformFee * rate : 0;
  };
  
  return (
    <>
      <div
        className={`summary-container ${isMobile && "mobile"} ${openItems && "opened"
          }`}
      >
        <div className="data-wrapper">
          <div className="booking-summary">
            <h4 className="meduim">Booking Summary</h4>
            <div className="details-container">
              <div className="data">
                <div className="item">
                  <span className="bold">Experience</span>
                  <p className="meduim">{bookingData?.experience_name}</p>
                </div>
                <div className={`item ${bookingData?.start_time?.experience?.scheduling_type === "pass" ? ' text-end ' : ' '}`}>
                  <span className="bold">Date</span>
                  <p className="meduim">{date}</p>
                </div>{" "}
                {bookingData?.start_time.start_time &&
                  <div className="item">
                    <span className="bold">Time</span>
                    <p className="meduim">{bookingData?.start_time.start_time}</p>
                  </div>
                }
              </div>
              {bookingData?.tickets[0].name === "booking" || bookingData?.start_time?.pricing?.pricing_type === "booking" ? (
                <div className="data users-details">
                    <div className="item extra">
                    <span className="bold">Category</span>
                    {bookingData?.tickets[0].name === "booking" ?
                    <p className="meduim">All</p>
                    :
                    bookingData?.tickets?.map((member, index) => (
                      <>
                        <div className="extra-data" key={index}>
                          <p className="meduim">{member.name}</p>
                        </div>
                      </>
                    ))}
                  </div>
                   <div className="item extra">
                    <span className="bold">Quantity</span>
                    {bookingData?.tickets?.map((member, index) => (
                      <>
                      {/* removed {bookingData?.no_of_ticket} */}
                        <p className="meduim ">{member.no_of_tickets}</p> 
                       
                      </>
                    ))}
                  </div>

                  <div className="item extra">
                    <span className="bold">Amount</span>
                    <p className="meduim">{" "}{(bookingData?.pricing[0]?.rate + calculatePlatformFee(bookingData?.pricing[0]?.rate)).toFixed(2)}{" AED"}</p>
                  </div>
                </div>
              ) : (
                <div className="data users-details">
                  <div className="item extra">
                    <span className="bold">Category</span>
                    {bookingData?.tickets?.map((member, index) => (
                      <>
                        <div className="extra-data" key={index}>
                          <p className="meduim">{member.name}</p>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="item extra">
                    <span className="bold">Quantity</span>
                    {bookingData?.tickets?.map((member, index) => (
                      <>
                        <p className="meduim ">{member.no_of_tickets}</p>
                       
                      </>
                    ))}
                  </div>

                  <div className="item extra">
                    <span className="bold">Amount</span>
                    {bookingData?.tickets?.map((member, index) => (
                      <>
                        <p className="meduim test">
                        {
                         ( (
                            bookingData?.pricing?.find(
                              pricing => pricing.name === member.name
                            )?.rate * member?.no_of_tickets
                          ) +
                          calculatePlatformFee(
                            bookingData?.pricing?.find(
                              pricing => pricing.name === member.name
                            )?.rate * member?.no_of_tickets
                          )).toFixed(2)
                        } {"AED"}

                        </p>
                      </>
                    ))}
                  </div>
                </div>
              )}
            
            {bookingData?.participent_info[0]?.extra.length > 0 && ( 
            
              <ExtrasDetails
                extras={bookingData?.participent_info[0]?.extra}
                is_commission_included={bookingData?.is_commission_included}
                platformFee={platformFee}
                formatPrice={formatPrice}
              />
            )}
                         
              {bookingData?.promo_code && (
                <div className="data">
                  <div className="item promo-code">
                    <p className="bold">Promo code</p>
                    <p className="promo">
                      {bookingData?.promo_code?.code}
                    </p>
                  </div>
                </div>
              )}
              {bookingData?.discount > 0 ?
                <div className="data price">
                  <div className="item promo-code">
                    <p className="bold">Discount</p>
                    <p className="">
                      {formatPrice(bookingData?.discount)} AED
                    </p>
                  </div>
                  <div className="item promo-code">
                    <p className="bold">Amount before VAT</p>
                    <p className="">
                      {formatPrice(bookingData?.payment_amount_before_tax)} AED
                    </p>
                  </div>
                  <div className="item promo-code">
                    <p className="bold">VAT Amount</p>
                    <p className="">
                      {formatPrice(bookingData?.payment_tax)} AED
                    </p>
                  </div>
                </div>
                :
                <div className="data price">
                  <div className="item promo-code">
                    <p className="bold">Amount before VAT</p>
                    <p className="">
                      {formatPrice(bookingData?.payment_amount_before_tax)} AED
                    </p>
                  </div>
                  <div className="item promo-code">
                    <p className="bold">VAT Amount</p>
                    <p className="">
                      {formatPrice(bookingData?.payment_tax)} AED
                    </p>
                  </div>
                </div>
              }
              <div className="data price">
                <div className="item promo-code">
                  <p className="bold">Total Amount</p>
                  <p className="">
                    {formatPrice(bookingData?.total_amount)} AED
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`footer`}>
          <div className="title">
            <p className="medium">Order Summary</p>
            <h1 className="bold summary-exp-ttile">
              {bookingData?.experience_name?.length > 30 ? `${bookingData?.experience_name?.substring(0, 30)}...` : bookingData?.experience_name}
            </h1>
          </div>
          <div className="total">
            <div className="item price">
              <span className="medium">Total amount</span>
              <p className="bold cap">
                {formatPrice(bookingData?.total_amount)} AED
              </p>
            </div>
          </div>
        </div>
        <CloseButton icon onClick={handleSummary} />
      </div>
      {openItems && isMobile && <div className="website-overlay"></div>}
    </>
  );
};

export default PaymentSummary;
